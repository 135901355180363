<template>
	<section
		v-if="content"
		:class="['freetext', classes, { dark }]"
	>
		<hr
			v-if="content.subscriptionText && content.subscriptionTextDivider"
			class="divider"
		/>
		<Component
			:is="headingTag"
			v-if="content.header && !content.subscriptionText"
			v-editable-string="'content.header'"
			:class="[marginClass, content && 'spacing-top']"
			:type="headingTag"
			>{{ content.header }}
		</Component>
		<strong
			v-else-if="content.header && content.subscriptionText"
			v-editable-string="'content.header'"
			>{{ content.header }}</strong
		>

		<RichText
			v-if="text && !table"
			:dark="dark"
			:text="text"
			class="body margin-bottom-l"
			editable-html="content.text"
		/>
		<RichText
			v-if="text && table"
			:dark="dark"
			:text="text"
			class="body margin-bottom-l"
			editable-html="content.text"
			style="overflow-x: auto"
		/>
		<RichText
			v-if="content.bodyText"
			:dark="dark"
			:text="content.bodyText"
			class="body margin-bottom-l"
			editable-html="content.bodyText"
		/>

		<TnButton
			v-if="button"
			:dark="dark"
			:data-comp-cat="bedriftCategory"
			:data-comp-lead="bedriftLead"
			v-bind="button"
		/>
	</section>
</template>

<script lang="ts">
import ButtonViewModel from "../../../../helpers/ViewModels/ButtonViewModel.js";
import SupportsDarkMode from "../../../../mixins/platform/SupportsDarkMode";

import type {Schema} from "./freetext.d.ts";

export default defineNuxtComponent({
	name: "Freetext",

	mixins: [SupportsDarkMode],

  props: {
    component: {
      type: Object as PropType<Schema>
    }
  },

  setup(props){
    const backgroundColor = useBackgroundColor(props.component.content.backgroundColor);
    return { backgroundColor }
  },

	computed: {
		content() {
			return this.component.content;
		},
		color() {
			return this.content.backgroundColor;
		},
		button() {
			return this.content.button && ButtonViewModel(this.content.button);
		},
		headingTag() {
			const headerType = this.content.headerType;

			//Fallback for old templates with classes property on template object
			const classes = (this.component.template && this.component.template.classes) || [];
			if (classes === "page-header" || classes === "large-header") return "h1";
			else if (classes === "small-header") return "h3";
			//End of fallback

			// Fallback for old header type names
			if (headerType === "page-header" || headerType === "large-header") return "h1";
			else if (headerType === "small-header") return "h3";
			else if (headerType !== "h1" && headerType !== "h2" && headerType !== "h3") return "h2";
			// End of fallback

			return headerType;
		},
		marginClass() {
			if (this.headingTag === "h1") return "margin-bottom-l";
			else return "margin-bottom-m";
		},
		classes() {
			//Fallback for old templates with classes property on template object
			let classes = (this.component.template && this.component.template.classes) || [];
			if (!Array.isArray(classes)) classes = classes.split(" ");
			//End of fallback
			else {
				if (this.headingTag === "h1") classes.push("page-header");
				if (this.content.subscriptionText) classes.push("subscriptionText");
			}

			if (this.color && this.color !== "transparent" && !this.dark && this.color !== "white") {
				classes.push("component-has-bg");
				classes.push(this.backgroundColor);
			}
			return classes.join(" ");
		},
		text() {
			if (this.content.text) return this.content.text.replace("&gt;", "&rsaquo;").replace(/<p><\/p>/gi, "");
			return this.content.text;
		},
		table() {
			return this.text.indexOf("table") > -1;
		},
	},
});
</script>

<style lang="scss" scoped>
.freetext {
	word-break: break-word;

	@include font-text-m;

	h1 {
		@include font-title-xl;
	}

	h2 {
		@include font-title-m;
	}

	h3 {
		@include font-title-s;
	}

	:deep(h1),
	h2,
	h3 {
		color: $color-primary-dark;
	}

	:deep() {
		color: $color-neutrals-900-shade;
	}

	&.component-has-bg {
		padding: $spacing-2xl;

		@include breakpoint(mobile) {
			padding: $spacing-xl;
		}
	}

	> *:last-child {
		margin-bottom: 0 !important;
	}

	&.dark {
		color: $color-neutrals-white;

		:deep() {
			h1,
			h2,
			h3 {
				color: $color-neutrals-white;
			}
		}
	}

	:deep(.body) {
		> * + * {
			margin-top: $spacing-m;
		}

		p {
			margin-bottom: $spacing-l;

			@include font-text-l;

			&:last-child {
				margin-bottom: 0;
			}
		}

		ul {
			li {
				margin-bottom: $spacing-s;

				p {
					margin-bottom: $spacing-s;
				}
			}
		}

		ol {
			li {
				margin-bottom: $spacing-s;

				p {
					margin-bottom: $spacing-s;
				}
			}
		}

		h1 {
			margin-bottom: $spacing-xl;

			@include font-title-xl;

			@include breakpoint(mobile) {
				margin-bottom: $spacing-m;
			}
		}

		> * + h1 {
			margin-top: $spacing-2xl;

			@include breakpoint(mobile) {
				margin-top: $spacing-l;
			}
		}

		h2 {
			margin-bottom: $spacing-m;

			@include font-title-m;

			@include breakpoint(mobile) {
				margin-bottom: $spacing-xs;
			}
		}

		> * + h2 {
			margin-top: $spacing-xl;

			@include breakpoint(mobile) {
				margin-top: $spacing-m;
			}
		}

		h3 {
			margin-bottom: $spacing-s;

			@include font-title-s;

			@include breakpoint(mobile) {
				margin-bottom: $spacing-2xs;
			}
		}

		> * + h3 {
			margin-top: $spacing-xl;

			@include breakpoint(mobile) {
				margin-top: $spacing-m;
			}
		}
	}

	&.extra-pusher {
		/* Used on form sending */
		margin-top: 150px;
		margin-bottom: 150px;

		@media (max-width: $size-screen-s) {
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}

	&.page-header {
		h1 {
			@include font-title-xl;
		}

		:deep(p) {
			@include font-text-xl;
		}
	}

	&.subscriptionText {
		width: inherit;

		.body {
			:deep(p) {
				color: $color-neutrals-900-shade;

				@include font-text-s;
			}
		}

		.spacing-top {
			margin-top: $spacing-m;
			padding-top: $spacing-l;
		}

		&.dark {
			.body {
				:deep(p) {
					color: $color-neutrals-100-tint;
				}
			}
		}
	}

	li,
	a,
	.body,
	div {
		font-size: inherit;
		font-family: inherit;
		line-height: inherit;
		margin-bottom: $spacing-m;

		@media screen and (max-width: $size-screen-s) {
			margin-bottom: $spacing-xs;
		}
	}

	p {
		font-size: inherit;
		font-family: inherit;
		line-height: inherit;
		margin-bottom: $spacing-l;

		&:last-child {
			margin-bottom: 0;
		}

		@media screen and (max-width: $size-screen-s) {
			margin-bottom: $spacing-s;
		}
	}

	.body {
		margin-bottom: 0;

		&.richtext :deep(em) {
			font-style: italic;
		}

		h2 {
			@include font-title-m;
		}

		* + div {
			margin-top: 30px;
		}

		span:not([class]) {
			border-bottom: 1px solid $color-neutrals-300-tint;
		}
	}

	strong {
		font-weight: bold;

		&:only-child {
			margin: $spacing-m 0;
		}
	}
}
</style>
